<template>
  <v-container fill-height>
    <v-row dense>
      <v-col cols="12" class="d-flex justify-center">
        <v-form v-model="valid" ref="form" aut-login-form>
          <v-card max-width="500">
            <v-card-title class="justify-center pt-8">
              <span class="headline text-h5 primary--text font-weight-medium"
                >{{ signInLabel }} to Your Account</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      name="username"
                      v-model="username"
                      outlined
                      :rules="rules"
                      label="Enter your Username"
                      validate-on-blur
                      @keydown.enter.prevent="signIn"
                      autocomplete="username"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      name="password"
                      autocomplete="current-password"
                      v-model="password"
                      outlined
                      :rules="rules"
                      label="Enter Password"
                      required
                      @keydown.enter.prevent="signIn"
                      :type="showContent ? 'text' : 'password'"
                      :append-icon="showContent ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showContent = !showContent"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions class="justify-space-between">
                <div class="d-flex ma-2 pr-6">
                  <router-link aut-registration-link to="/register"
                    >Register</router-link
                  >
                  <router-link class="ml-2" to="/reset_password"
                    >Forgot Password</router-link
                  >
                </div>
                <v-btn
                  id="aut-signin"
                  color="primary"
                  :disabled="!valid"
                  :loading="showLoader"
                  @click.stop="signIn"
                >
                  {{ signInLabel }}
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { rules } from "@/util.js";
const debug = require("debug")("atman.components.login"); // eslint-disable-line
export default {
  name: "Login",
  data() {
    return {
      showLoader: false,
      valid: false,
      signInLabel: "Sign In",
      rules: [rules.mandatory()],
      username: "",
      password: "",
      showContent: false,
    };
  },
  methods: {
    ...mapActions("user", ["login"]),
    async signIn() {
      if (!this.valid) {
        return;
      }
      const component = this;
      try {
        component.showLoader = true;
        await component.login({
          username: component.username,
          password: component.password,
        });
      } catch (e) {
        component.$refs.form.reset();
      } finally {
        component.showLoader = false;
      }
    },
  },
};
</script>
